// import {DeepChat as DeepChatCore} from 'deep-chat'; <- type
import { RequestDetails } from "deep-chat/dist/types/interceptors";
import { DeepChat } from "deep-chat-react";
import "./App.css";

export const App = ({ name = "" }) => {
  const history = [
    // { role: "user", text: "Hey, how are you today?" },
    // { role: "ai", text: "I am doing very well!" },
  ];

  return (
    <div className="App">
      <h3 className="white-bg">Welcome, I am Ola's Personal Assistant </h3>
      <DeepChat
        style={{
          borderRadius: "10px",
          width: "95vw",
          height: "calc(90vh - 70px)",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
        history={history}
        connect={{ url: process.env.REACT_APP_API_URL, stream: false }}
        requestBodyLimits={{ maxMessages: 30 }}
        requestInterceptor={(details: RequestDetails) => {
          return details;
        }}
        responseInterceptor={(response: any) => {
          return response;
        }}
        textInput={{
          styles: {
            container: {
              width: "100%",
              margin: "0",
              border: "unset",
              borderTop: "1px solid #d5d5d5",
              borderRadius: "0px",
              boxShadow: "unset",
            },
            text: {
              fontSize: "1.05em",
              paddingTop: "11px",
              paddingBottom: "13px",
              paddingLeft: "12px",
              paddingRight: "2.4em",
            },
          },
          placeholder: {
            text: "Welcome!, Ask me anything about Olamide's professional experience",
            style: { color: "#bcbcbc" },
          },
        }}
        submitButtonStyles={{
          submit: {
            container: {
              default: {
                transform: "scale(1.21)",
                marginBottom: "-3px",
                marginRight: "0.4em",
              },
            },
          },
        }}
      />
    </div>
  );
};
